import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <>
    <div className="container padded p-relative" style={{ zIndex: '10' }}>
      <section className="row" id="404">
        <div className="col-12 col-lg-10 col-xl-8 mx-auto ">
          <div className="page-content text-center pt-5">
            <h1
              style={{
                paddingBottom: '.25em',
                display: 'inline-block',
                borderBottom: '3px solid #545ff5',
              }}
            >
              404
            </h1>
            <h2>Page not found</h2>
            <p
              style={{
                marginTop: '2em',
                marginBottom: '2.5em',
              }}
            >
              It appears the page you were looking for couldn't be found.
            </p>
            <Link to="/" className="btn btn-primary purple-btn">
              I'm Feeling Lucky
            </Link>
          </div>
        </div>
      </section>
    </div>
  </>
)

export default NotFoundPage
